$white: #fffaf0;

$sky-fallback: #654ea3;
$sky-webkit: -webkit-linear-gradient(to bottom, #fcf3d8 30%, #eaafc8, #654ea3);
$sky: linear-gradient(to top, #fcf3d8 30%, #eaafc8, #654ea3);
$scene-size: 360px;
$num-mountains: 8;
$hill: #82d9a2;

$head-radius-top: 50% 20px;
$head-radius-bottom: 14.4px;
$bulbasaur-body: darken(#56ffe9, 20%);
$bulb: #00d6ba;
$bulb-dark: darken($bulb, 10%);
$pika-body: #fff069;
$pika-brown: #9c5200;

$moon-size: 60px;

$flight: 15s;
$sparkle-colors: #fffce6, #ffeefc, #f3ffe4, #e9fbff;

@function randomNum($min, $max) {
  $rand: random();
  @return ($min + floor($rand * (($max - $min) + 1)));
}

@mixin head-styles($color) {
  position: absolute;
  width: 48px;
  height: 36px;
  background-color: $color;
  border-top-left-radius: $head-radius-top;
  border-top-right-radius: $head-radius-top;
  border-bottom-left-radius: $head-radius-bottom;
  border-bottom-right-radius: $head-radius-bottom;
}

@mixin gradient-colors($first, $second, $direction) {
  background: $second;
  background: -webkit-linear-gradient($direction, $first, $second);
  background: linear-gradient($direction, $first, $second);
}

@mixin hill-styles($size, $top, $left) {
  $half: $size/2;
  $quarter: $size/4;
  width: $size;
  height: $half;
  top: $top;
  left: $left;
  border-top-left-radius: $half $quarter;
  border-top-right-radius: $half $quarter;
  border-bottom-left-radius: $half $quarter;
  border-bottom-right-radius: $half $quarter;
  box-shadow: inset 20px 30px 50px darken(#4ca2cd, 15%);
}

@mixin sparkleColor($color) {
  background-color: $color;
  box-shadow: 0px 0px 10px $color;
}

@mixin flight($aniName, $x, $y) {
  @keyframes #{$aniName} {
    95% {
      opacity: 1;
    }
    100% {
      transform: translate($x, $y) scale(0);
      opacity: 0;
    }
  }
}

@mixin animateSparkles($delay, $start, $end, $ani, $x, $y, $inc, $delta) {
  @include flight($ani, $x, $y);
  @for $i from $start through $end {
    &:nth-child(#{$i}) {
      animation: #{$ani} $flight + $inc $delay infinite;
      $delay: $delay + $delta;
    }
  }
}

.pic-ul {
  padding: 0;
}
.pic-li {
  list-style: none;
}

.pic-container {
  background-image: $sky;
  background-image: $sky-webkit;
  background-color: $sky-fallback;
  height: $scene-size;
  width: $scene-size;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  overflow: hidden;
}

.mountain-range {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 50%;
  display: flex;
  justify-content: center;
  padding: 0;

  .mountain {
    @for $i from 1 through $num-mountains {
      &:nth-child(#{$i}) {
        $mountain-size: randomNum(100px, 160px);
        $margin-top: randomNum(14px, 42px);
        width: 0;
        height: 0;
        margin-top: $margin-top;
        margin-left: -60%;
        border-left: $mountain-size solid transparent;
        border-right: $mountain-size solid transparent;
        border-bottom: $mountain-size/2 solid darken(rgb(168, 212, 255), 10%);
      }
    }
  }
}
.forest {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 65%;
  .hill {
    position: absolute;
    background-color: $hill;
    &:nth-child(1) {
      @include hill-styles(480px, -8px, -120px);
    }
    &:nth-child(2) {
      @include hill-styles(400px, -4px, 80px);
    }
    &:nth-child(3) {
      @include hill-styles(400px, 14px, -144px);
    }
  }
}

.grass {
  position: absolute;
  width: 480px;
  height: 240px;
  border-radius: 240px / 120px;
  top: 75%;
  left: -80px;
  background: $hill;
  box-shadow: inset 20px 30px 60px darken(#4ca2cd, 15%);
}

.pokemon {
  position: absolute;
  width: 240px;
  height: 88px;
  left: calc(53% - 120px);
}

.poke {
  position: absolute;
  width: 80px;
  height: 80px;
}

#bulbasaur {
  &:before {
    content: '';
    position: absolute;
    width: 80px;
    height: 40px;
    background: rgba(#000, 0.2);
    border-radius: 100%;
    top: 40px;
    left: 12px;
    transform: rotate(-15deg);
  }
  .head {
    @include head-styles($bulbasaur-body);
    left: 44px;
  }
  .ear {
    position: absolute;
    height: 16px;
    width: 18px;
    background-color: $bulbasaur-body;
    border-top-left-radius: 40px 72px;
    border-top-right-radius: 40px 72px;
    transform-origin: center bottom;

    &:nth-child(1) {
      transform: rotate(-45deg);
      left: 42px;
      top: -4px;
      animation: rotateLeftBulbEar 2s alternate infinite;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
      left: 70px;
      top: -4px;
      animation: rotateRightBulbEar 2s alternate infinite;
    }
  }

  .bulba-body {
    position: relative;
    width: 52px;
    height: 44px;
    background-color: $bulbasaur-body;
    left: 33px;
    top: 16px;
    border-radius: 100%;
    box-shadow: inset 6.4px -6.4px darken($bulbasaur-body, 4%);
    animation: bodyBreathe 2s alternate infinite;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      width: 8px;
      height: 10.4px;
      top: 26.4px;
      left: 6.4px;
      background-color: darken($bulbasaur-body, 8%);
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 30%;
      border-top-right-radius: 30%;
      border-top-left-radius: 50%;
      transform: rotate(-45deg);
    }
    &:after {
      width: 6.4px;
      height: 6.4px;
      top: 24px;
      left: 42.4px;
      background-color: darken($bulbasaur-body, 4%);
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 30%;
      border-top-right-radius: 30%;
      border-top-left-radius: 50%;
      transform: rotate(65deg);
    }
  }

  .leg {
    position: absolute;
    width: 16px;
    height: 32px;
    background-color: $bulbasaur-body;
    border-bottom-left-radius: 28px 110%;
    border-bottom-right-radius: 28px 110%;
    box-shadow: inset 8px -16px darken($bulbasaur-body, 5%);
    left: 72px;
    top: 24px;
  }
  .bulbs {
    position: absolute;
    top: 0px;
    width: 44px;
    height: 40px;
    left: 42px;
    transform-origin: center;
    transform: rotate(45deg);
    z-index: 3;
    animation: bulbaBreathe 2s alternate infinite;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 36px;
      width: 36px;
      border-radius: 0 50% 50% 50%;
    }
    &:before {
      transform: translate(-26px, 8px) rotate(7deg);
      background-color: darken($bulb-dark, 10%);
    }
    &:after {
      transform: translate(-14px, -3px) rotate(-7deg);
      background-color: darken($bulb-dark, 5%);
    }
  }

  .bulb {
    position: absolute;
    top: 8px;
    right: 1.6px;
    left: 2.4px;
    width: 90%;
    height: 90%;
    border-radius: 0 50% 100% 50%;
    background-color: $bulb-dark;
    z-index: 4;
    box-shadow: inset 1px -4px darken($bulb-dark, 3%);
  }
}

#pikachu {
  left: -0.1em;
  &:before {
    content: '';
    position: absolute;
    width: 48px;
    height: 64px;
    background: rgba(#000, 0.2);
    border-radius: 100%;
    top: 40px;
    left: 92px;
    transform: rotate(40deg);
  }
  .ear {
    position: absolute;
    height: 32px;
    width: 14.4px;
    background-color: $pika-body;
    border-top-left-radius: 48px 120px;
    border-top-right-radius: 48px 120px;
    transform-origin: center bottom;
    overflow: hidden;

    &:nth-child(1) {
      transform: rotate(-35deg);
      left: 100px;
      top: -28px;
      animation: rotateLeftEar 1s alternate infinite;

      &:after {
        content: '';
        position: absolute;
        background-color: #444;
        width: 150%;
        height: 12px;
        left: -6.4px;
        transform: rotate(-30deg);
      }
    }
    &:nth-child(2) {
      transform: rotate(50deg);
      left: 125.6px;
      top: -24px;
      animation: rotateRightEar 1s alternate infinite;

      &:after {
        content: '';
        position: absolute;
        background-color: #444;
        width: 150%;
        height: 12px;
        left: 1.6px;
        transform: rotate(30deg);
      }
    }
  }
  .hand {
    position: absolute;
    height: 30.4px;
    width: 12px;
    background-color: $pika-body;
    border-top-left-radius: 48px 120px;
    border-top-right-radius: 48px 120px;
    transform-origin: center bottom;
    left: 98.4px;
    top: 4px;
    z-index: 5;
    transform: rotate(-40deg);
    animation: pikaPat 0.7s alternate infinite;
  }

  .head {
    @include head-styles($pika-body);

    left: 96px;
    width: 48px;
    height: 44px;
    top: -9.6px;
    // transform: rotate(10deg);
  }

  .pika-body {
    position: absolute;
    width: 44px;
    height: 44px;
    background-color: $pika-body;
    left: 97.6px;
    top: 16px;
    border-radius: 17.6px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: $pika-brown;
      width: 70%;
      height: 5.6px;
      left: 10.4px;
      border-radius: 100%;
    }

    &:before {
      top: 12px;
      z-index: 2;
    }
    &:after {
      top: 24px;
    }
  }

  .pika-tail {
    position: absolute;
    height: 20px;
    width: 12.8px;
    background: $pika-brown;
    border-radius: 4px;
    left: 120px;
    top: 32px;
    transform-origin: bottom center;
    transform: rotate(55deg);
    box-shadow: -2px 2px 1px rgba(#444, 0.2);
    z-index: 2;
    animation: rotateTail 2s alternate infinite;

    &:before,
    &:after {
      content: '';
      transform-origin: bottom center;
      position: absolute;
      background-color: $pika-body;
    }

    &:before {
      height: 1.16rem;
      width: 1.12rem;
      top: -1.6rem;
      left: 0.2rem;
      transform: rotate(-90deg);
      border: 1px solid $pika-brown;
      border-bottom-right-radius: 4px;
      z-index: 4;
      border-top: 1px solid $pika-body;
    }

    &:after {
      background: $pika-body;
      height: 2.4rem;
      width: 1.7rem;
      top: -2.24rem;
      left: -2rem;
      border-radius: 4px 4px 0 4px;
      border: 1px solid $pika-brown;
    }
  }
}

.moon {
  position: absolute;
  width: $moon-size;
  height: $moon-size;
  @include gradient-colors(#ffffff, #ffefba, 135deg);
  border: 4px solid $white;
  top: 10%;
  left: calc(50% - 36px);
  padding: 0;
  border-radius: 100%;

  li {
    position: absolute;
    border-radius: 100%;
    $dark: darken(#ffefba, 8%);
    &:nth-child(1) {
      @include gradient-colors(rgba($white, 0.1), $dark, 45deg);
      width: 16px;
      height: 16px;
      left: 4px;
      top: 24px;
    }
    &:nth-child(2) {
      @include gradient-colors(rgba($white, 0.1), $dark, unquote('to left'));
      width: 28px;
      height: 28px;
      left: 20px;
      top: 24px;
    }
    &:nth-child(3) {
      @include gradient-colors(rgba($white, 0.1), $dark, -120deg);
      width: 20px;
      height: 20px;
      left: 16px;
      top: 4px;
    }
  }
}

.sparkles {
  position: absolute;
  width: 120%;
  height: 152px;
  top: 30%;
  left: -36px;
  .sparkle {
    position: absolute;
    width: 8.8px;
    height: 8.8px;
    border-radius: 100%;
    @for $i from 1 through 32 {
      &:nth-child(#{$i}) {
        $index: randomNum(1, 4);
        @include sparkleColor(nth($sparkle-colors, $index));
      }
    }
  }
}

.pic-one {
  left: 0;
  top: 0;
  @include animateSparkles(0s, 1, 4, flyOne, 216px, 60px, 0, 6.44);
}

.pic-two {
  right: 0;
  @include animateSparkles(2.7s, 5, 8, flyTwo, -216px, 60px, 6, 5.3);
}

.pic-three {
  left: 0;
  top: 100%;
  @include animateSparkles(1.4s, 9, 12, flyThree, 216px, -60px, 2, 8.5);
}

.pic-four {
  right: 0;
  top: 100%;
  @include animateSparkles(5.8s, 13, 16, flyFour, -216px, -60px, 10, 4.6);
}

.pic-five {
  left: 0;
  top: 50%;
  @include animateSparkles(8.7s, 17, 20, flyFive, 216px, 0px, 20, 5.7);
}

.pic-six {
  right: 0;
  top: 50%;
  @include animateSparkles(10.6s, 21, 24, flySix, -216px, 0px, 8.5, 4.4);
}

.pic-seven {
  left: 0;
  top: 25%;
  @include animateSparkles(5s, 25, 28, flySeven, 216px, 29.6px, 14, 7.67);
}

.pic-eight {
  right: 0;
  top: 25%;
  @include animateSparkles(1.6s, 29, 32, flyEight, -270px, 29.6px, 13.5, 3.74);
}

@keyframes pikaPat {
  from {
    transform: rotate(-40deg);
  }
  to {
    transform: rotate(-50deg);
  }
}
@keyframes rotateTail {
  from {
    transform: rotate(55deg);
  }
  to {
    transform: rotate(65deg);
  }
}
@keyframes rotateLeftEar {
  from {
    transform: rotate(-35deg);
  }
  to {
    transform: rotate(-40deg);
  }
}
@keyframes rotateRightEar {
  from {
    transform: rotate(50deg);
  }
  to {
    transform: rotate(60deg);
  }
}
@keyframes bulbaBreathe {
  from {
    transform: rotate(45deg) scale(1);
  }
  to {
    transform: rotate(45deg) scale(1.01);
  }
}
@keyframes bodyBreathe {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes rotateLeftBulbEar {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(-55deg);
  }
}
@keyframes rotateRightBulbEar {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(55deg);
  }
}
