* {
  box-sizing: border-box;
}

body {
  background-color: #fffaf0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  min-height: 750px;
}

main {
  height: 85vh;
  width: 100vw;
  min-width: 750px;
  min-height: 700px;
}
@media (max-height: 455px) {
  body {
    min-height: 580px;
  }
  main {
    min-height: 275px;
    min-width: 600px;
  }
}

/* Footer - start */
#copyright {
  width: 100%;
  min-width: 750px;
  max-height: 8vh;
  font-size: 10px;
  color: grey;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (max-width: 740px) {
  #copyright {
    padding-left: 0;
    padding-right: 20px;
    font-size: 7px;
  }
}

@media (max-width: 606px) {
  #copyright {
    min-width: 606px;
    font-size: 7px;
  }
}

#copyright a,
a:hover {
  color: inherit;
}
/* Footer - end */

/* Header - start */
.pokemons {
  content: url('https://assets.meaah.com/file/meaah-poke/lib/images/header/mypokemons.png');
}

.egg {
  content: url('https://assets.meaah.com/file/meaah-poke/lib/images/header/egg.png');
}

.meaah {
  content: url('https://assets.meaah.com/file/meaah-poke/lib/images/header/meaahicon.png');
}

.profile-icon {
  content: url('https://assets.meaah.com/file/meaah-poke/lib/images/header/trainer.png');
}

.header-meaah-logo {
  width: 70px;
  min-width: 40px;
  height: 70px;
  margin-left: 10px;
}

.header-icons {
  margin-top: 5px;
}

#header-greeting {
  margin-top: 20px;
  margin-bottom: 14px;
  min-width: 135px;
  max-height: 30px;
}

#header-pikachu-gif {
  height: 60px;
  margin-top: 5px;
}

#header-buttons {
  padding-top: 7px;
}

@media (max-height: 455px) {
  .header-meaah-logo {
    width: 52px;
    height: 52px;
  }

  #header-container {
    height: 55px;
  }

  .header-icons {
    margin-top: 0;
  }

  #header-greeting {
    margin-top: 13px;
  }

  #header-pikachu-gif {
    height: 50px;
  }
  #header-buttons {
    padding-top: 0;
  }
}

/* Header - end */

/* Score - start */
#header-score {
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 10px;
  min-width: 117px;
  max-height: 35px;
  padding-top: 10px;
}

@media (max-height: 455px) {
  #header-score {
    margin-top: 14px;
    height: 30px;
    min-width: 80px;
  }

  #header-score-logo {
    padding-bottom: 4px;
  }
}

/* Score - end */

/* Homepage - start */
.homepage-container {
  height: 80vh;
  min-width: 650px;
  position: absolute;
  top: 12%;
}

#home-title {
  width: 40%;
  height: 50%;
  margin-top: 15px;
}

.game-container {
  padding: 12px 5px;
  border: 3px solid #fffaf0;
  border-radius: 15px;
}

.game-container-signed-in {
  padding: 12px 5px;
  border: 3px solid #356bbc;
  border-radius: 15px;
}

.instructions-container {
  background-color: #fffaf0;
  border-radius: 5px;
  padding-top: 8px;
  height: 100%;
}

.instructions-container-signed-in {
  background-color: #c7f4b0;
  border-radius: 5px;
  padding-top: 8px;
  height: 100%;
}

.instructions {
  text-align: left;
  padding-left: 25px;
}

@media (max-height: 455px) {
  .homepage-container {
    max-width: 600px;
    height: 88vh;
    top: 15%;
    left: 0%;
    text-align: center;
  }

  .instructions-container {
    padding: 2px;
  }

  .instructions-container-signed-in {
    padding: 2px;
  }

  .instructions-title {
    font-size: 1em;
    margin: 0;
  }

  .instructions {
    font-size: 0.8em;
    line-height: 1em;
    margin-top: 0;
  }
}

/* Homepage - end */

/* LoginPage - start  */
#login-container {
  height: 70vh;
  padding-top: 20px;
}

@media (max-height: 455px) {
  #login-container {
    padding-top: 0px;
    position: fixed;
    left: 29%;
  }
}
/* LoginPage - end  */

/* RegisterPage - start  */
#register-container {
  height: 80vh;
  padding-top: 45px;
}

#register-form-container {
  max-width: 450px;
}

@media (max-height: 455px) {
  #register-container {
    width: 420px;
    position: fixed;
    padding-top: 0px;
    left: 21%;
    top: 11%;
  }

  #register-image {
    height: 30px;
    width: 30px;
  }

  #register-header {
    margin-bottom: 5px;

    font-size: 20px;
  }
}

/* RegisterPage - end  */

/* ProfilePage - end  */
#profile-page-container {
  height: 100%;
  width: 100%;
  min-width: 740px;
}

#pic-column {
  height: 82vh;
  min-width: 370px;
  top: 11px;
}

#form-column {
  height: 62vh;
  min-width: 370px;
  padding-right: 30px;
}

@media (max-height: 455px) {
  #profile-page-container {
    min-width: 600px;
    position: absolute;
    left: 25px;
    top: -20px;
  }

  #profile-logo {
    height: 45px;
    width: 45px;
  }

  #profile-header {
    font-size: 22px;
  }
}

/* ProfilePage - end  */

/* PokeFriendsPic copyright notice - start */
.pokefriends-copyright {
  text-align: left;
  font-size: 10px;
  line-height: 100%;
  color: grey;
  position: fixed;
  bottom: 0;
  padding-left: 28px;
  padding-right: 15px;
  padding-bottom: 45px;
  width: '40%';
}

.pokefriends-copyright a,
a:hover {
  color: inherit;
}

@media (max-height: 455px) {
  .pokefriends-copyright {
    font-size: 8px;
    padding: 5px;
  }
}

/* PokeFriendsPic copyright notice - end */

/* GuessPokemonPage - start */
.pokeguess-title-image {
  height: 50px;
  margin: auto;
}

#pokeguess-page-grid-container {
  height: 85vh;
  min-width: 740px;
  padding-top: 50px;
}

#pokeguess-left-column-img {
  height: 60%;
  padding-left: 10px;
  padding-top: 55px;
}

#pokeguess-poke-container {
  top: 35px;
  left: 35%;
}

#pokeguess-loader {
  height: 200px;
  width: 200px;
  border-radius: 180px;
}

#pokeguess-pokemon-img {
  height: 200px;
  width: 200px;
  max-height: 200px;
  padding: 10px;
}

.poke-shadow {
  filter: contrast(0%) brightness(50%);
}

#poke-guess-answer-form {
  padding: 15px;
  margin-top: 70px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}

#column-right {
  padding-top: 55px;
}

#pokeguees-reward-label {
  font-size: 12px;
  height: 34px;
  background-color: #fffaf0;
  padding-top: 11px;
}

#pokeguees-reward-details {
  color: brown;
  background-color: #fffaf0;
  padding-top: 8px;
  padding-left: 0;
  margin-left: 0;
  font-size: 17px;
}

@media (max-height: 455px) {
  #pokeguess-page-grid-container {
    padding: 0;
  }

  .pokeguess-title-image {
    padding: 0;
    height: 40px;
    top: -3%;
  }

  #pokeguess-left-column-img {
    height: 35%;
    padding-top: 10px;
    padding-left: 55px;
    margin-left: 15px;
  }

  #pokeguess-poke-container {
    top: -20px;
  }

  #pokeguess-loader {
    height: 120px;
    width: 120px;
  }

  #pokeguess-pokemon-img {
    height: 120px;
    width: 120px;
    padding: 5px;
  }

  #poke-guess-answer-form {
    margin-top: 10px;
  }

  #column-right {
    padding-top: 5px;
    padding-left: 0;
  }

  #correct-ans-logo {
    height: 80px;
    width: 80px;
    margin-left: 40px;
  }

  #wrong-ans-logo {
    height: 90px;
    width: 80px;
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  #pokeguess-poke-container {
    left: 20%;
  }
}

@media (min-width: 1200px) {
  #pokeguess-poke-container {
    left: 50%;
  }
}

/* GuessPokemonPage - end */

/* LetterKeys - start */
#letterKeys-container {
  height: 20vh;
  min-height: 160px;
  min-width: 750px;
}

#keyboard {
  height: 160px;
}

.letterKeys-btn {
  width: 52px;
  height: 52px;
  background-color: rgb(15, 173, 15);
  color: white;
  margin-right: 7px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  border: none;
  outline: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}

.letterKeys-btn:focus {
  background-color: rgb(15, 173, 15);
  color: white;
  outline: none;
}

.letterKeys-btn:hover {
  /* background-color: rgb(12, 143, 12); */
  background-color: white;
  color: rgb(15, 173, 15);
  border: 2px solid rgb(15, 173, 15);
}

@media (max-height: 455px) {
  #letterKeys-container {
    min-height: 110px;
    min-width: 600px;
    position: relative;
  }

  #keyboard {
    position: absolute;
    height: 100px;
    left: 10px;
    top: -5px;
    padding-left: 3px;
  }
  .letter-row {
    height: 40px;
  }

  .letterKeys-btn {
    max-width: 40px;
    max-height: 40px;
    margin-right: 3px;
    font-size: 16px;
  }

  #keyboard-img {
    width: 75px;
    height: 75px;
    margin-bottom: 70px;
    margin-left: 10px;
    left: 285px;
  }
}

@media (max-width: 995px) {
  .letterKeys-btn {
    width: 40px;
    height: 40px;
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .letterKeys-btn {
    width: 64px;
    height: 64px;
  }
}

/* LetterKeys - end */

/* GuessWordPage - start */
#wordguess-page-grid-container {
  height: 85vh;
  min-width: 740px;
  padding-top: 50px;
  min-height: 700px;
}

#wordguess-title-row {
  padding: 0;
  margin: 0;
  height: 10vh;
  min-height: 80px;
}

.wordguess-title-image {
  height: 80px;
}

#wordguess-middle-container {
  height: 45vh;
  min-height: 400px;
}

#wrongguess-container {
  margin-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  min-height: 125px;
  border: 1px dashed #6435c9;
}

.wrong-letter {
  width: 35px;
  height: 35px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: red;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  border: none;
  outline: none;
}

.answer-msg {
  font-size: 16px;
  height: 39px;
  padding: 5px;
  border: 1px solid #9dc5cd;
  border-radius: 4px;
  background-color: #f8ffff;
  color: #276f86;
}

.losegame-img {
  height: 150px;
  width: 150px;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(80%) translateY(-80%);
}

.wingame-img {
  height: 130px;
  width: 150px;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(95%) translateY(-80%);
}

#split-word-container {
  padding-top: 80px;
  font-size: 3rem;
  line-height: 1;
  color: #f38130;
}

#repeat-alert {
  font-size: 16px;
}

#wordguess-right-column {
  position: relative;
}

.wordguess-definition {
  color: #f2711d;
  font-size: 15px;
}

@media (max-height: 455px) {
  #wordguess-page-grid-container {
    min-height: 450px;
    min-width: 600px;
    padding-top: 0;
    position: fixed;
    top: 70px;
    left: -14px;
  }

  #wordguess-title-row {
    min-height: 50px;
  }

  #guesses-left {
    line-height: 1;
    margin-top: 15px;
  }

  .wordguess-title-image {
    height: 50px;
  }

  #wordguess-middle-container {
    height: 45vh;
    min-height: 280px;
  }

  #game-main-msg {
    position: relative;
    margin: auto;
    max-height: 35px;
    font-size: 13px;
    line-height: 10px;
  }

  #repeat-alert {
    margin: auto;
    font-size: 13px;
    max-height: 40px;
  }

  #small-reset-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    width: 30px;
  }

  .answer-msg {
    font-size: 13px;
    height: 39px;
    padding: 5px;
  }

  .losegame-img {
    height: 130px;
    width: 130px;
    top: 0%;
    left: 50%;
  }

  .wingame-img {
    height: 90px;
    width: 110px;
    left: 55%;
  }

  .wordguess-letters {
    font-size: 35px;
  }

  #wordguess-definition {
    font-size: 16px;
  }

  .wordguess-definition {
    font-size: 12px;
  }

  #guesses-left {
    font-size: 14px;
  }

  #wrongguess-container {
    min-height: 65px;
  }

  #wrongguess-header {
    font-size: 14px;
  }

  .wrong-letter {
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
/* GuessWordPage - end */

/* PokemonsPage - start */
#pokemons-page-grid-container {
  min-width: 740px;
  padding-top: 50px;
}

.mypokemons-title-image {
  height: 70px;
}

.pokecard-main-container {
  background-color: #eee;
  min-width: 150px;
  border-radius: 10px;
  box-shadow: 0 3px 15px rgba(100, 100, 100, 0.5);
  margin: 10px;
  padding: 15px;
  text-align: center;
}

.pokecard-main-container .pokecard-img-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  text-align: center;
}

.pokecard-main-container .pokecard-img-container .pokecard-img {
  max-width: 90%;
  margin-top: 10px;
}

.pokecard-main-container .pokecard-info {
  margin-top: 20px;
}

.pokecard-main-container .pokecard-info .number {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.8em;
}

.pokecard-main-container .pokecard-info .name {
  margin: 15px 0 7px;
  letter-spacing: 1px;
}

.pokecard-main-container .pokecard-info .types {
  min-width: 55px;
  color: white;
}

@media (max-height: 455px) {
  #pokemons-page-grid-container {
    min-width: 640px;
    padding-top: 5px;
    position: absolute;
    top: 70px;
    left: 14px;
  }

  .mypokemons-title-image {
    height: 45px;
  }

  #total-pokemons {
    font-size: 16px;
  }

  .pokecard-main-container {
    background-color: #eee;
    min-width: 110px;
    margin: 10px;
    padding: 15px;
  }

  .pokecard-main-container .pokecard-img-container {
    width: 70px;
    height: 70px;
    margin-left: 8px;
  }

  .pokecard-main-container .pokecard-img-container .pokecard-img {
    max-width: 90%;
    margin-top: 5px;
  }

  .pokecard-main-container .pokecard-info {
    margin-top: 11px;
  }

  .pokecard-main-container .pokecard-info .number {
    padding: 3px 7px;
    font-size: 0.7em;
  }

  .pokecard-main-container .pokecard-info .name {
    margin: 5px 0 5px;
    font-size: 12px;
  }

  .pokecard-main-container .pokecard-info .types {
    min-width: 40px;
    font-size: 8px;
  }
}
/* PokemonsPage - end */

/* EggsPage - start */
#eggs-page-grid-container {
  min-width: 740px;
  padding-top: 50px;
}

.eggs-title-image {
  height: 90px;
}

#header-coin {
  margin-bottom: 5px;
}

#header-egg {
  width: 65px;
  height: 70px;
  margin-bottom: 20px;
  margin-left: 0;
}

#eggs-row {
  margin-top: 3%;
}

.incubator-container {
  margin: 10px;
}

.incubator-img {
  margin-bottom: 15px;
}

@media (max-height: 455px) {
  #eggs-page-grid-container {
    min-width: 640px;
    padding-top: 15px;
    position: absolute;
    top: 70px;
    left: 8px;
  }

  .eggs-title-image {
    height: 60px;
    width: 300px;
  }

  #header-coin {
    margin-bottom: 5px;
    width: 40px;
    height: 40px;
  }

  #header-egg {
    width: 50px;
    height: 55px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  #eggs-row {
    margin-top: 0;
  }

  .incubator-container {
    margin: 10px;
  }

  .incubator-img {
    margin-bottom: 15px;
  }

  .incubator-btn {
  }
}
/* EggsPage - end */

/* EggsHatchPage - start */
#eggs-hatch-main-container {
  min-width: 740px;
  width: 100%;
}

#eggs-hatch-container {
  color: white;
  height: 45vh;
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: 28px;
}

#crackedegg-img {
  position: absolute;
  width: 20%;
  max-width: 200px;
  height: 70%;
  max-height: 200px;
  left: 42%;
  top: 20%;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
  -moz-filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
  -webkit-filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
}

.crackedegg-shake {
  animation: shake 2s;
  -moz-animation: shake 2s;
  -webkit-animation: shake 2s;
  animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-moz-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.crackedegg-hatch {
  animation: hatch 1s;
  -moz-animation: hatch 1s;
  -webkit-animation: hatch 1s;
  animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@keyframes hatch {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes hatch {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-moz-keyframes hatch {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

#eggs-hatch-quiz-container {
  color: white;
  top: 50%;
  text-align: center;
  position: absolute;
}

.poke-img {
  margin-top: 5%;
  margin-bottom: 5px;
  width: 170px;
  height: 170px;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5));
  -moz-filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5));
  -webkit-filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5));
}

.poke-loader {
  margin-top: 5%;
  margin-bottom: 5px;
  width: 170px;
  height: 170px;
}

#quiz-box {
  margin-top: 15px;
  padding: 40px;
  background-color: #323232;
  opacity: 0.75;
  border: 1px solid white;
  border-radius: 15px;
}

#quiz-question {
  margin-bottom: 20px;
}

@media (max-height: 455px) {
  #eggs-hatch-main-container {
    min-width: 620px;
    position: absolute;
    left: 3%;
    top: 16%;
  }

  #eggs-hatch-container {
    margin-top: 0;
    position: absolute;
  }

  #crackedegg-img {
    max-width: 100px;
    max-height: 100px;
    left: 42%;
    top: 30px;
  }

  #hatchegg-header {
    position: absolute;
    top: -15%;
  }

  #eggs-hatch-quiz-container {
    top: 50%;
    min-height: 240px;
    position: absolute;
  }

  .poke-img {
    margin-bottom: 5px;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    left: 45%;
  }

  .poke-loader {
    margin-top: 45%;
    margin-bottom: 5px;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    left: 45%;
  }

  #hatchegg-pokename-title {
    position: absolute;
    top: 75%;
    left: 41%;
  }

  .end-btn-1 {
    position: absolute;
    top: 90%;
    left: 30%;
  }

  .end-btn-2 {
    position: absolute;
    top: 90%;
    left: 52%;
  }

  #progress-bar {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
  }

  #quiz-box {
    min-width: 620px;
    padding: 10px;
    position: absolute;
    top: 100px;
  }

  #quiz-question {
    margin-bottom: 10px;
  }
}

/* EggsHatchPage - end */
